import { Button } from "@components/common";
import { addToCart, closeCart } from "@store/cart";
import { Link } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";
import { Product } from "@helpers/getProduct";

interface UpsellCartItemProps {
  product: Product;
}

const UpsellCartItem = ({ product }: UpsellCartItemProps) => {
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    const cartItem = {
      product: {
        ...product,
        deliveryFrequency: "MONTHLY",
      },
      quantity: 1,
    };

    dispatch(addToCart({ cartItems: [cartItem] }));
  };

  const handleCloseCart = () => {
    dispatch(closeCart());
  };

  return (
    <div className="cart-body-upsell-item">
      <div className="cart-body-upsell-item-block">
        <Link
          to={`/product/${product.slug}`}
          className="cart-body-upsell-link"
          onClick={handleCloseCart}
        >
          <div className="cart-body-upsell-item-img">
            <img src={product.cartImage} alt={product.name} />
          </div>
        </Link>
        <div className="cart-body-upsell-item-content">
          <Link
            to={`/shop/${product.slug}`}
            className="cart-body-upsell-link"
            onClick={handleCloseCart}
          >
            <span className="cart-body-upsell-item-content-name">{product.name}</span>
            <span className="cart-body-upsell-item-content-price">${product.price} </span>
          </Link>
          <Button
            icon="arrow-right"
            onClick={handleAddToCart}
            className="cart-body-upsell-item-content-button"
          >
            Add
          </Button>
        </div>
      </div>

      <Button
        icon="arrow-right"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleAddToCart();
        }}
        className="cart-body-upsell-item-content-button-mobile"
      >
        Add
      </Button>
    </div>
  );
};

export default UpsellCartItem;
