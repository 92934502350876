import React, { Dispatch, FC, SetStateAction } from "react";

import { CheckoutFormType } from "@pages/checkout";

import InputField from "@components/common/InputField";
import RadioButtonGroup from "@components/common/RadioButtonGroupField";
import PersonalInfoSummary from "@components/checkout/PersonalInfoSummary";

import { Option } from "@components/common/RadioButton";
import Icon from "@components/common/Icon";

export type ShippingPrice = {
  price?: {
    label: string;
    value: number;
  };
};

export type ShippingType = "FREE" | "PRIORITY_SHIPPING";

export type ShippingTypesOptions = Option & ShippingPrice;

export const shippingTypesOptions: ShippingTypesOptions[] = [
  {
    label: "Free shipping (1-2 Business Days)",
    value: "FREE",
    price: {
      label: "",
      value: 0,
    },
    helper: (
      <React.Fragment>
        <span>Courier company: </span>
        <img
          src="/images/common/delivery/usps.svg"
          alt="Usps courier company"
          className="courier-company-icon"
        />
      </React.Fragment>
    ),
  },
  // {
  //     label: 'Priority Shipping (Next Day)',
  //     value: 'PRIORITY_SHIPPING',
  //     price: {
  //         label: '$14.99',
  //         value: 14.99,
  //     },
  // },
];

interface Props {
  formType: CheckoutFormType;
  shipping: ShippingType;
  setFormType: (newFormType: CheckoutFormType) => void;
  setFocus: Dispatch<SetStateAction<string | undefined>>;
  handleChangeShipping: (value: any) => void;
}

const CheckoutPaymentForm: FC<Props> = ({
  shipping,
  handleChangeShipping,
  formType,
  setFormType,
  setFocus,
}) => {
  if (formType !== "payment") return null;

  return (
    <div className="form-content payment-form">
      <PersonalInfoSummary setFormType={setFormType} setFocus={setFocus} />

      <h2 className="checkout-title">Shipping Method</h2>

      <div className="card shipping-form">
        <ul className="list-group">
          <RadioButtonGroup
            value={shipping}
            onChange={handleChangeShipping}
            name="shippingType"
            options={shippingTypesOptions}
          />
        </ul>
      </div>

      <h2 className="checkout-title">Payment</h2>
      <div className="description-muted">
        <span>All transactions are secure and encrypted.</span>
      </div>

      <div className="card">
        <div className="card-header">
          <Icon className="icon" name="shield-check-mark" />
          <p className="title">Secure Card Payment</p>
          <img
            src="/images/payment-methods.png"
            alt="Payment methods"
            className="payment-methods"
            width={304}
            height={30}
          />
        </div>

        <div className="card-body">
          <div className="row">
            <InputField
              name="cardNumber"
              autoComplete="cc-number"
              label="Card number"
              mask="9999 9999 9999 9999"
              maskChar=""
              iconName="lock"
              iconClassName="card-number-icon"
              tooltip={
                <p>
                  All transactions are <br /> secure and encrypted.
                </p>
              }
            />
          </div>
          <div className="row">
            <InputField name="cardName" autoComplete="cc-name" label="Name on card" />
          </div>

          <div className="row">
            <InputField
              name="cardExpiry"
              label="Expiration date (MM / YY)"
              mask="99/99"
              autoComplete="cc-exp"
            />

            <InputField
              name="cardCode"
              label="Security code"
              mask="9999"
              autoComplete="cc-csc"
              maskChar=""
              iconName="question-mark"
              tooltip={
                <p>
                  3-digit security code <br /> usually found on the back <br /> of your card.
                  American <br /> Express cards have a 4-
                  <br />
                  digit code located on the <br />
                  front.
                </p>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPaymentForm;
