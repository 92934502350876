/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, Fragment, useEffect, MouseEvent, useRef, useState } from "react";

import { Link, PageProps, navigate } from "gatsby";

import classNames from "classnames";

import Icon from "@components/common/Icon";
import Button from "@components/common/Button";
import Banner from "@components/common/Banner";
import ProductDropDownMenu from "@components/common/ProductDropDownMenu";

import { selectCartItems, toggleCart } from "@store/cart";
import { useDispatch, useSelector } from "react-redux";
import { gaViewCart } from "@analytics/google";

const Header: FC<{ location: PageProps["location"] }> = ({ location }) => {
  const body = useRef<HTMLElement>();
  const [isSafari, setIsSafari] = React.useState(true);
  const [isActive, setIsActive] = useState(false);

  React.useEffect(() => {
    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }, []);

  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();

  const handleToggleCart = () => {
    dispatch(toggleCart());
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleCartClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    handleToggleCart();
    gaViewCart(cartItems);
  };

  const onMouseLeave = (): void => {
    setIsActive(false);
  };

  const toggleMenu = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>
  ): void => {
    e.stopPropagation();

    if (isMobile) {
      setIsOpen((prevIsOpen) => {
        if (prevIsOpen) {
          body?.current?.classList.remove("modal-open");

          return !prevIsOpen;
        }

        body?.current?.classList.add("modal-open");

        return !prevIsOpen;
      });
    }
  };

  const handleLogoClick = (): void => {
    setIsOpen(false);
    body?.current?.classList.remove("modal-open");
  };

  useEffect(() => {
    body.current = document.body;
    body.current.classList.remove("modal-open");

    if (window?.innerWidth < 1023) {
      setIsMobile(true);
    }

    const handleResize = (): void => {
      if (window?.innerWidth > 1023) {
        body?.current?.classList.remove("modal-open");
        setIsOpen(false);
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };

    window?.addEventListener("resize", handleResize);

    return () => {
      body?.current?.classList.remove("modal-open");
      window?.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      <header
        className={classNames("header-container", { open: isOpen })}
        onMouseLeave={onMouseLeave}
      >
        <Banner location={location} />
        <nav className="header">
          <div className="nav-btn-container">
            <button
              className="nav-btn burger-button"
              type="button"
              onClick={toggleMenu}
              aria-label="burger-menu"
            >
              <Icon name="burger" />
            </button>
          </div>
          <div className="items">
            <ProductDropDownMenu
              toggleMenu={toggleMenu}
              isActive={isActive}
              setIsActive={setIsActive}
              location={location}
            />
            <Link
              to="/about-us"
              className="nav-link"
              onClick={toggleMenu}
              onMouseEnter={onMouseLeave}
            >
              About Us
            </Link>
            <Link to="/faqs" className="nav-link" onClick={toggleMenu} onMouseEnter={onMouseLeave}>
              FAQS
            </Link>
          </div>
          <Link
            to={location.pathname.includes("box") ? "/box" : "/"}
            className="logo"
            onClick={handleLogoClick}
            aria-label="Medipups"
            onMouseEnter={onMouseLeave}
          >
            <Icon name="logo" width={180} height={37} />
          </Link>
          <div className="buttons">
            <Button
              className="cart-button"
              color="link"
              icon="cart"
              onClick={handleCartClick}
              aria-label="cart"
              onMouseEnter={onMouseLeave}
            >
              {cartItems.length > 0 && (
                <div className="cart-button-counter">{cartItems.length}</div>
              )}
            </Button>
            {isSafari ? (
              <a
                className="profile-button btn btn-link with-icon my-profile new-tab"
                href={`https://customerportal.revcent.com/${process.env.GATSBY_CUSTOMER_PORTAL_KEY}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="profile" />
              </a>
            ) : (
              <Link
                color="link"
                to="/dashboard"
                className="profile-button btn btn-link with-icon my-profile"
                aria-label="Profile"
                onMouseEnter={onMouseLeave}
              >
                <Icon name="profile" />
              </Link>
            )}

            <Button
              className="cta-button"
              color="yellow"
              icon="gift"
              onClick={() => navigate(["/box/"].includes(location.pathname) ? "/wizard" : "/box")}
            >
              Get FREE Gift
            </Button>
          </div>
        </nav>
      </header>

      <div className="header-overlay" onClick={toggleMenu} />
    </Fragment>
  );
};

export default Header;
