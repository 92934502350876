import React, { useCallback } from "react";

import classNames from "classnames";

import { isChewProduct, isMedBoxProduct } from "@utils/product";
import { DELIVERY_FREQUENCY_MAP } from "@constants/product";
import { CartItem as ICartItem } from "@typings/cart";

import { useDispatch } from "react-redux";
import { closeCart } from "@store/cart";
import { navigate } from "gatsby";

import CartItemProperty from "./property/CartItemProperty";
import CartItemFooter from "./footer/CartItemFooter";
import CartItemImg from "./img/CartItemImg";
import CartItemHeader from "./title/CartItemHeader";

export type CartItemType = "EDIT" | "ADD";

interface CartItemProps {
  cartItem: ICartItem;

  className?: string;

  type?: CartItemType;

  hideRemoveBtn?: boolean;
  hideCounter?: boolean;
}

const CartItem = ({
  cartItem,
  className,
  type = "EDIT",
  hideRemoveBtn,
  hideCounter,
}: CartItemProps) => {
  const { product } = cartItem;

  const dispatch = useDispatch();

  const redirectToProductPage = useCallback(() => {
    if (product.type === "GIFT") return;

    if (product.slug) {
      void navigate(`/product/${product.slug}`);
    }

    dispatch(closeCart());
  }, [dispatch, product.slug]);

  return (
    <React.Fragment>
      <div className={classNames("cart-item", className)}>
        <CartItemImg cartItem={cartItem} redirectToProductPage={redirectToProductPage} />

        <div className="cart-item__info">
          <CartItemHeader cartItem={cartItem} redirectToProductPage={redirectToProductPage} />

          <div className="cart-item__properties">
            {isChewProduct(product) && (
              <CartItemProperty name={DELIVERY_FREQUENCY_MAP[product.deliveryFrequency]} />
            )}

            {isMedBoxProduct(product) && (
              <CartItemProperty name={DELIVERY_FREQUENCY_MAP[product.subscriptionType]} />
            )}
          </div>

          <CartItemFooter
            cartItem={cartItem}
            type={type}
            hideRemoveBtn={hideRemoveBtn}
            hideCounter={hideCounter}
          />
        </div>
      </div>

      <hr className="cart-item__separator" />
    </React.Fragment>
  );
};

export default CartItem;
