/* eslint-disable import/no-dynamic-require */

/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/prefer-default-export */
// using relative path to not crash generateKlaviyoCatalog script
import { RealResultsProps } from "@components/sections/productPage/realResults";

import { FaqSectionData } from "@configs/faqSectionData";

import AgaveIcon from "@assets/svg/ingredients/agave.svg";
import AppleIcon from "@assets/svg/ingredients/apple.svg";
import AshwagandhaIcon from "@assets/svg/ingredients/ashwagandha.svg";
import BeefIcon from "@assets/svg/ingredients/beef.svg";
import BiotinIcon from "@assets/svg/ingredients/biotin.svg";
import CfuIcon from "@assets/svg/ingredients/cfu.svg";
import CurcuminIcon from "@assets/svg/ingredients/curcumin.svg";
import FlowerIcon from "@assets/svg/ingredients/flower.svg";
import GlucosamineIcon from "@assets/svg/ingredients/glucosamine.svg";
import HoneyIcon from "@assets/svg/ingredients/honey.svg";
import LTheanineIcon from "@assets/svg/ingredients/l-theanine.svg";
import MusselsIcon from "@assets/svg/ingredients/mussels.svg";
import OilIcon from "@assets/svg/ingredients/oil.svg";
import OmegaIcon from "@assets/svg/ingredients/omega.svg";
import OrganicIcon from "@assets/svg/ingredients/organic.svg";
import PumpkinIcon from "@assets/svg/ingredients/pumpkin.svg";
import TurmericIcon from "@assets/svg/ingredients/turmeric.svg";
import VitaminECIcon from "@assets/svg/ingredients/vitamin-e-c.svg";

import { KeyIngredient, keyIngredients } from "./data/keyIngredients";
import { ProductName } from "./getProduct";

export type ProductsData = {
  [name in ProductName]: ProductData;
};

export type ProductData = {
  name?: ProductName;
  secondName: string;
  slug: string;
  image: string;
  cartImage?: string;
  images: ProductImages;
  color: string;
  keyIngredients: KeyIngredient[];
  description: string;
  qualities: string[];
  imageIngredients: string[];
  fullIngredients: {
    title: string;
    ingredients?: string[];
    list?: string[];
  }[];
  frequentlyBrought: {
    id: string;
    name: ProductName;
    image: string;
  };
  pawGress?: {
    main: string
    mobile?: string
  }
  realResults: RealResultsProps;
  faq: FaqSectionData[];
};

export interface ProductImages {
  mainImage: string;
  mainProductImage: string;
  additionalImages: string[];
  quantityImages: string[];
  ingredients: string[];
  benefitsImage: string;
  feedingGuide: string;
  benefitsImageMobile?: string;
}

export type ProductImagesList = {
  [productName in ProductName]: ProductImages;
};

export const getAdditionalImagesPaths = (
  path: string,
  count: number,
  format = "png",
  startIndex = 0
): string[] =>
  Array.from({ length: count }, (_, i) => `/images/${path}/${startIndex + i + 1}.${format}?v=2`);

const generatePathsFromFileNames = ({
  fileNames,
  folderName,
}: {
  fileNames: string[];
  folderName: string;
}): string[] => {
  return fileNames.map((fileName) => `/images/${folderName}/${fileName}?v=2`);
};

const calmingChewsAdditionalImages = generatePathsFromFileNames({
  fileNames: !process.env.GATSBY_ENABLE_FREE_GIFT
    ? ["2-old.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg"]
    : ["2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg", "9.jpg", "10.jpg"],
  folderName: "calming/gallery",
});

const probioticChewsAdditionalImages = generatePathsFromFileNames({
  fileNames: !process.env.GATSBY_ENABLE_FREE_GIFT
    ? ["2-old.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg"]
    : ["2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", "7.jpg", "8.jpg"],
  folderName: "probiotic/gallery",
});

const productData: ProductsData = {
  "Calming Chews": {
    secondName: "Calming",
    slug: "calming-chews",
    color: "#8ABF6A",
    frequentlyBrought: {
      id: "qZmbYGZrRMTQPRaBbdkA",
      name: "Probiotic Chews",
      image: "/images/probiotic/quantity/1.png",
    },
    description:
      "MediPups' Calming Chews have been uniquely formulated and are a bespoke formula for your dogs, one that can promote relaxation and composure, especially during stressful situations. Our scientifically designed chew contains key ingredients, including 3 billion CFU per soft chew, Passion Flower, Suntheanine®, and Shoden® Ashwagandha, which reduce bodily impact of stress, fear, and anxiety without drowsiness. This ultimately lessens jumping, barking, biting and other aggressive behavior.",
    keyIngredients: keyIngredients["Calming Chews"],
    qualities: [
      "Promotes relaxation and composure, without drowsiness or lethargy.",
      "Reduces jumping, barking, biting and other aggressive behavior.",
      "Supports the circadian rhythm to maintain a natural sleep cycle.",
      "Reduces anxiety, enhances relaxation, and provides calming effects.",
      "Organic, All Natural, Made In The U.S.A.",
    ],
    faq: [
      {
        name: "start-using",
        title: "At what age can my pup start using Medipups Calming Chews?",
        content:
          "Our calming soft chews are an amazing solution for dogs of any breed. This chew is to be given to dogs over 12 weeks and older. If you have concerns or questions please reach out to your vet as well as us, we are here 24/7.",
      },
      {
        name: "how-many-in-tub",
        title: "How many Calming chews come in a tub?",
        content: "Each tub contains 30 chews.",
      },
      {
        name: "how-it-works",
        title: "How quickly do the Calming Chew for dogs work?",
        content:
          "We created our Calming Chews as an helpful, fast remedy. The ingredients have a calming effect on the nerves and helps maintain a calm & relaxed disposition. These supplements seriously help dogs with signs of anxiety of stress, aggressiveness and those who exhibit nervousness. Medipups calming chews help curb these behaviors. Please check directions for use to see how many need to be given to your dog daily: continued intake will increase efficacy.",
      },
      {
        name: "what-flavor",
        title: "What flavor are Medipups’ Calming chews?",
        content: "Beef Flavored: Our chews will leave your pup begging for MORE!",
      },
      {
        name: "chews-is-safe",
        title: "What happens if I accidentally give my dog too many Calming chews?",
        content:
          "Please see a veterinarian as soon as possible if you accidentally give too many. If you’re unsure how many chews to give your dog, please see the directions for use at the top of the page.",
      },
      {
        name: "product-safe-for-humans",
        title: "Is this product safe for humans?",
        content:
          "All our products are intended to be consumed by dogs and are not safe for human consumption. Please keep packages out of reach of children.",
      },
    ],
    pawGress: {
      main: "/images/calming/paw-gress.png",
      mobile: "/images/calming/paw-gress-mobile.jpg",
    },
    image: "/images/calming/main.png",
    cartImage: "/images/calming/cart-img.jpg",
    images: {
      mainImage: "/images/calming/main.png",
      mainProductImage: "/images/calming/main-product.jpg",
      benefitsImage: "/images/calming/benefits.png",
      additionalImages: calmingChewsAdditionalImages,
      ingredients: getAdditionalImagesPaths("calming/ingredients", 5, "jpg"),
      quantityImages: getAdditionalImagesPaths("calming/quantity", 5, "png"),
      feedingGuide: "/images/calming/gallery/9.jpg",
    },
    realResults: {
      video: "https://www.youtube.com/watch?v=XfUQ-1xSXuc",
      description:
        "The first night I gave him Medipups Calming chews he immediately was able to wind down and relax.",
      author: "Madison Cartwright",
    },
    imageIngredients: [AshwagandhaIcon, FlowerIcon, BeefIcon, LTheanineIcon, CfuIcon, OrganicIcon],
    fullIngredients: [
      {
        title: "Active Ingredients:",
        ingredients: [
          "Proprietary blend of Passion Flower Powder",
          "Shoden® Ashwagandha",
          "Suntheanine®",
          "L-Tryptophan",
        ],
      },
      {
        title: "Other Ingredients:",
        ingredients: [
          "Chickpea",
          "Vegetable Glycerin",
          "Beef",
          "Brewer's Yeast, Sunflower Oil",
          "Sweet Potato",
          "Vitamin E Oil",
          "Salmon",
          "Honey",
        ],
      },
      {
        title: "Product Facts:",
        list: ["30 Chews per bottle", "Net contents: 4.23 oz (120g)"],
      },
      {
        title: "Storage",
        list: ["Store in a cool dry place out of the reach of children and animals."],
      },
    ],
  },
  "Joint Care Chews": {
    secondName: "Mobility",
    slug: "joint-care-chews",
    color: "#E5624F",
    frequentlyBrought: {
      id: "j0wYMmJ4a6Hdp1G8rJKM",
      name: "Calming Chews",
      image: "/images/calming/quantity/1.png",
    },
    description:
      "MediPups' Joint Care chews are formulated specifically to support & assist your dog’s joints so they can live as healthy, happy, and active as possible. Our chews are extremely powerful and each tasty chew is packed with healthy supplements like Green Lipped Mussels, Salmon Oil, and Glucosamine. These supplements support both your dog’s joint health in crucial, key stages of development and maintain older dog’s mobility.",
    keyIngredients: keyIngredients["Joint Care Chews"],
    qualities: [
      "Designed to support joint health and comfort.",
      "Assists mobility and strength for active lifestyles.",
      "Provides support for occasional stiffness and discomfort caused by daily exercise and activities.",
      "Organic, All Natural, Made In The U.S.A.",
    ],
    faq: [
      {
        name: "start-using",
        title: "At what age can my pup start using Joint care chews?",
        content:
          "Our joint care soft chews are an amazing solution for dogs of any breed. We recommend introducing this supplement slowly, when they are around 12 weeks old. If you have concerns or questions please reach out to your vet as well as us, we are here 24/7.",
      },
      {
        name: "how-many-in-tub",
        title: "How many Joint care chews come in a tub?",
        content: "Each tub contains 30 chews.",
      },
      {
        name: "what-flavor",
        title: "What flavor are Medipups’ Joint care chews?",
        content: "Pork Flavored: Our chews will leave your pup begging for MORE!",
      },
      {
        name: "accidentally-give",
        title: "What happens if I accidentally give my dog too many Joint Care chews?",
        content:
          "Please see a veterinarian as soon as possible if you accidentally give too many. If you’re unsure how many chews to give your dog, please see the directions for use at the top of the page.",
      },
      {
        name: "chews-is-safe",
        title: "Are the Joint Care chews safe for a dog with Kidney Disease?",
        content:
          "Our products are organic and an all natural solution. We use ingredients of the highest quality, all manufactured in the USA to ensure your dog reacts effectively and safely. Please consult with your vet first if you have any questions or concerns.",
      },
      {
        name: "product-safe-for-humans",
        title: "Is this product safe for humans?",
        content:
          "All our products are intended to be consumed by dogs and are not safe for human consumption. Please keep packages out of reach of children",
      },
    ],
    image: "/images/joint-care/main.png",
    cartImage: "/images/joint-care/cart-img.jpg",
    images: {
      mainImage: "/images/joint-care/main.png",
      mainProductImage: "/images/joint-care/main-product.jpg",
      benefitsImage: "/images/joint-care/benefits.png",
      additionalImages: getAdditionalImagesPaths("joint-care/gallery", 6, "jpg"),
      ingredients: getAdditionalImagesPaths("joint-care/ingredients", 5, "jpg"),
      quantityImages: getAdditionalImagesPaths("joint-care/quantity", 5, "png"),
      feedingGuide: "/images/joint-care/gallery/4.jpg",
    },
    realResults: null,
    imageIngredients: [GlucosamineIcon, CurcuminIcon, OilIcon, MusselsIcon, OrganicIcon],
    fullIngredients: [
      {
        title: "Active Ingredients:",
        ingredients: ["Glucosamine", "Green Lipped Mussel", "Anchovy Oil", "Curcugen®."],
      },
      {
        title: "Other Ingredients:",
        ingredients: [
          "Chickpea",
          "Vegetable Glycerin",
          "Vitamin E Oil",
          "Salmon",
          "Pork",
          "Sunflower Oil",
          "Sweet Potato",
          "Pea Protein",
        ],
      },
      {
        title: "Product Facts:",
        list: ["30 Chews per bottle", "Net contents: 4.23 oz (120g)"],
      },
      {
        title: "Storage",
        list: ["Store in a cool dry place out of the reach of children and animals."],
      },
    ],
  },
  "Probiotic Chews": {
    secondName: "Probiotics & Gut Health",
    slug: "probiotic-chews",
    color: "#12BBB6",
    frequentlyBrought: {
      id: "j0wYMmJ4a6Hdp1G8rJKM",
      name: "Calming Chews",
      image: "/images/calming/quantity/1.png",
    },
    description:
      "Have you tried everything to help ease your dog's itching, paw licking, and head shaking? Chances are, you are not targeting the source. Your dog's GUT is the control center for their wellbeing and when their gut health is out of balance, it will cause them to itch and paw lick.. MediPups' Probiotic chews have been uniquely formulated to provide a blend of antioxidants & live cultures to maintain good gut bacteria. These powerful ingredients support your dog’s digestive system, promoting a comfortable, healthy, and active lifestyle.",
    keyIngredients: keyIngredients["Probiotic Chews"],
    qualities: [
      "Uniquely Formulated With 8 Strains Of Beneficial Flora.",
      "Helps Maintain Healthy Yeast Production & Healthy Intestinal Tract.",
      "Supports Seasonal Allergies & Immune System.",
      "87% Of Probiotic Chew Subscribers Surveyed Saw Results After 60 Days Of Daily Use.",
      "Organic, All Natural, Made In The U.S.A.",
    ],
    faq: [
      {
        name: "start-using",
        title: "At what age can my pup start using Probiotic chews?",
        content:
          "Our probiotic soft chews are an amazing solution for dogs of any breed. We recommend introducing this supplement slowly, when they are around 6 months old. If you have concerns or questions please reach out to your vet as well as us, we are here 24/7.",
      },
      {
        name: "how-many-in-tub",
        title: "How many Probiotic chews come in a tub?",
        content: "Each tub contains 30 chews.",
      },
      {
        name: "what-flavor",
        title: "What flavor are Medipups’ Probiotic chews?",
        content: "Beef Flavored: Our chews will leave your pup begging for MORE! ",
      },
      {
        name: "accidentally-give",
        title: "What happens if I accidentally give my dog too many Probiotic chews?",
        content:
          "Please see a veterinarian as soon as possible if you accidentally give too many. If you’re unsure how many chews to give your dog, please see the directions for use at the top of the page.",
      },
      {
        name: "chews-is-safe",
        title: "Are the Probiotic chews safe for a dog with Kidney Disease?",
        content:
          "Our products are organic and an all natural solution. We use ingredients of the highest quality, all manufactured in the USA to ensure your dog reacts effectively and safely. Please consult with your vet first if you have any questions or concerns.",
      },
      {
        name: "product-safe-for-humans",
        title: "Is this product safe for humans?",
        content:
          "All our products are intended to be consumed by dogs and are not safe for human consumption. Please keep packages out of reach of children.",
      },
    ],
    pawGress: {
      main: "/images/probiotic/paw-gress.png",
      mobile: "/images/probiotic/paw-gress-mobile.jpg",
    },
    image: "/images/probiotic/main.png",
    cartImage: "/images/probiotic/cart-img.jpg",
    images: {
      mainImage: "/images/probiotic/main.png",
      mainProductImage: "/images/probiotic/main-product.jpg",
      benefitsImage: "/images/probiotic/benefits.png",
      additionalImages: probioticChewsAdditionalImages,
      ingredients: getAdditionalImagesPaths("probiotic/ingredients", 4, "jpg"),
      quantityImages: getAdditionalImagesPaths("probiotic/quantity", 5, "png"),
      feedingGuide: "/images/probiotic/gallery/7.jpg",
    },
    realResults: {
      video: "https://www.youtube.com/watch?v=ag9bOWwrXFg",
      description:
        "Her gut health is getting better and we've seen absolutely drastic changes for her, for the better.",
      author: "Lola's Dad",
    },
    imageIngredients: [AgaveIcon, HoneyIcon, PumpkinIcon, BeefIcon, CfuIcon, OrganicIcon],
    fullIngredients: [
      {
        title: "Active Ingredients:",
        ingredients: ["A Proprietary Blend of Lactobacillus", "Pumpkin", "Honey", "Inulin (Agave)"],
      },
      {
        title: "Other Ingredients:",
        ingredients: [
          "Beef",
          "Chickpea Flour",
          "Sweet Potato Flour",
          "Vegetable Glycerin",
          "Brewer's Yeast Powder",
          "Water",
          "Molasses Powder",
          "Fish Oil",
          "Salmon",
          "Beef Cured",
          "Tapioca Flour",
        ],
      },
      {
        title: "Product Facts:",
        list: ["30 Chews per bottle", "Net contents: 4.23 oz (120g)"],
      },
      {
        title: "Storage",
        list: ["Store in a cool dry place out of the reach of children and animals."],
      },
    ],
  },
  "Skin & Coat Chews": {
    secondName: "Skin & Coat",
    slug: "skin-coat-chews",
    color: "#F3D152",
    frequentlyBrought: {
      id: "qZmbYGZrRMTQPRaBbdkA",
      name: "Probiotic Chews",
      image: "/images/probiotic/quantity/1.png",
    },
    description:
      "Your dog's skin health and coat comfort is important! Enhance their skin & coat with our tasty chews. It is just an easy and tasty daily routine that takes seconds a day. By doing this, you can maintain normal inflammatory responses and keep your dog's skin moisturized! More importantly, you are supporting your dog's skin & coat from the inside out, which is crucial for long term comfort and overall health.",
    keyIngredients: keyIngredients["Skin & Coat Chews"],
    qualities: [
      "Promotes Comfortable Skin & Healthy Coat.",
      "Contains Premium, High-Quality Fatty Acids That Maintain Skin Moisture.",
      "Only Takes Seconds A Day, And Dog-Approved!",
      "Organic, All Natural, Made In The U.S.A.",
    ],
    faq: [
      {
        name: "start-using",
        title: "At what age can my pup start using Skin & Coat care chews?",
        content:
          "Our skin & coat soft chews are an amazing solution for dogs of any breed. We recommend introducing this supplement slowly, when they are around 12 weeks old. If you have concerns or questions please reach out to your vet as well as us, we are here 24/7.",
      },
      {
        name: "how-many-in-tub",
        title: "How many Skin & Coat chews come in a tub?",
        content: "Each tub contains 30 chews.",
      },
      {
        name: "what-flower",
        title: "What flavor are Medipups’ Skin & Coat chews?",
        content: "Smoked Salmon Flavored: Our chews will leave your pup begging for MORE!",
      },
      {
        name: "chews-is-safe",
        title: "What happens if I accidentally give my dog too many Skin & Coat chews?",
        content:
          "Please see a veterinarian as soon as possible if you accidentally give too many. If you’re unsure how many chews to give your dog, please see the directions for use at the top of the page.",
      },
      {
        name: "product-safe-for-humans",
        title: "Is this product safe for humans?",
        content:
          "All our products are intended to be consumed by dogs and are not safe for human consumption. Please keep packages out of reach of children",
      },
    ],
    image: "/images/skin-coat/main.png",
    cartImage: "/images/skin-coat/cart-img.jpg",
    images: {
      mainImage: "/images/skin-coat/main.png",
      mainProductImage: "/images/skin-coat/main-product.jpg",
      benefitsImage: "/images/skin-coat/benefits.png",
      additionalImages: getAdditionalImagesPaths("skin-coat/gallery", 6, "jpg"),
      ingredients: getAdditionalImagesPaths("skin-coat/ingredients", 3, "jpg"),
      quantityImages: getAdditionalImagesPaths("skin-coat/quantity", 5, "png"),
      feedingGuide: "/images/skin-coat/gallery/4.jpg",
    },
    realResults: null,
    imageIngredients: [OmegaIcon, TurmericIcon, AppleIcon, VitaminECIcon, BiotinIcon, OrganicIcon],
    fullIngredients: [
      {
        title: "Active Ingredients:",
        ingredients: [
          "Omega Fatty Acids",
          "Turmeric",
          "Apple Cider Vinegar",
          "Vitamin E",
          "Vitamin C",
          "Biotin",
        ],
      },
      {
        title: "Other Ingredients:",
        ingredients: [
          "Brewer's Dried Yeast",
          "Vegetable Glycerin",
          "Smoked Salmon",
          "Honey",
          "Molasses",
          "Sunflower",
          "Tapioca Starch.",
        ],
      },
      {
        title: "Product Facts:",
        list: ["30 Chews per bottle", "Net contents: 4.23 oz (120g)"],
      },
      {
        title: "Storage",
        list: ["Store in a cool dry place out of the reach of children and animals."],
      },
    ],
  },

  "Anti-Anxiety Calming Bed": {
    name: "Anti-Anxiety Calming Bed",
    secondName: "Calming Bed",
    slug: "anti-anxiety-calming-bed",
    color: "#F3D152",
    frequentlyBrought: {
      id: "j0wYMmJ4a6Hdp1G8rJKM",
      name: "Calming Chews",
      image: "/images/calming/quantity/1.png",
    },
    description: `OVER 23,000,000 DOGS IN THE UNITED STATES SUFFER FROM ANXIETY!\n
Medipups bespoke beds are tailor-made to alleviate your pet's daily anxiety. It is one of the most premium quality beds ever produced: expertly crafted with a premium nylon fabric and finished with a high-end faux fur shag.\n
Our signature soft vegan fur with a raised rim imitates a mother's comforting presence, promoting a sense of safety and reducing vulnerability.\n
This calms and relaxes your furry friend, leading to improved sleep. With 1 in 4 dogs experiencing daily anxiety, it's crucial to treat your furry companion to avoid potential health issues and behavioral problems.\n
Tails will be wagging in no time!`,
    keyIngredients: [],
    qualities: [
      "Secure Transaction 🔒",
      "Ships From U.S.A 🇺🇸",
      "1000s Of Verified 5 Star Reviews ✨🌟",
      "No More Anxiety For Your Pup + Sleepless Nights 😴🐶",
      "60 Day Money-Back Guarantee 💰🤝",
      "Free Insured & Tracked Shipping With Medipups Plus 🚚📦",
    ],
    faq: [
      {
        name: "start-using",
        title: "At what age can my pup start using Skin & Coat care chews?",
        content:
          "Our skin & coat soft chews are an amazing solution for dogs of any breed. We recommend introducing this supplement slowly, when they are around 12 weeks old. If you have concerns or questions please reach out to your vet as well as us, we are here 24/7.",
      },
      {
        name: "how-many-in-tub",
        title: "How many Skin & Coat chews come in a tub?",
        content: "Each tub contains 30 chews.",
      },
      {
        name: "what-flower",
        title: "What flavor are Medipups’ Skin & Coat chews?",
        content: "Smoked Salmon Flavored: Our chews will leave your pup begging for MORE!",
      },
      {
        name: "chews-is-safe",
        title: "What happens if I accidentally give my dog too many Skin & Coat chews?",
        content:
          "Please see a veterinarian as soon as possible if you accidentally give too many. If you’re unsure how many chews to give your dog, please see the directions for use at the top of the page.",
      },
      {
        name: "product-safe-for-humans",
        title: "Is this product safe for humans?",
        content:
          "All our products are intended to be consumed by dogs and are not safe for human consumption. Please keep packages out of reach of children",
      },
    ],
    image: "/images/beds/beige.png",
    images: {
      mainImage: "/images/beds/beige.png",
      mainProductImage: "/images/beds/beige.png",
      benefitsImage: "/images/beds/benefits.jpg",
      benefitsImageMobile: "/images/beds/benefits.jpg",
      additionalImages: getAdditionalImagesPaths("beds/additional", 5, "png"),
      ingredients: getAdditionalImagesPaths("skin-coat/ingredients", 3, "jpg"),
      quantityImages: ["/images/beds/beige.png"],
      feedingGuide: "/images/skin-coat/gallery/4.jpg",
    },
    realResults: null,
    imageIngredients: [],
    fullIngredients: [],
  },

  "Safe Buckle™ Dog Seatbelt": {
    name: "Safe Buckle™ Dog Seatbelt",
    secondName: "Dog Seatbelt",
    slug: "safe-buckle-dog-seatbelt",
    color: "black",
    frequentlyBrought: {
      id: "j0wYMmJ4a6Hdp1G8rJKM",
      name: "Calming Chews",
      image: "/images/calming/quantity/1.png",
    },
    description: `EVERY UNRESTRAINED CAR JOURNEY PUTS YOU AND YOUR DOG AT RISK\n
        Each day, thousands of dog owners drive their pups around in the car. The dogs walk up and down the back, climb all over the seats, and get in and out of the driver's lap. In case of a crash, this can be extremely dangerous for the dog — and it can be risky for anyone else in the car as well. \n
        We understand what it's like to want to bring your dog with you wherever you go. When you hop in the car, the first thing you do is clip the seatbelt across your lap. But what's available to keep a dog re-strained in case of an accident? \n
        Help protect these furry ones today!`,
    keyIngredients: [],
    qualities: [
      "Secure Transaction 🔒",
      "Ships From U.S.A 🇺🇸",
      "1000s Of Verified 5 Star Reviews ✨🌟",
      "Keep your dog safe and secure 🐶❤️",
      "60 Day Money-Back Guarantee 💰🤝",
      "Free Insured & Tracked Shipping With Medipups Plus 🚚📦",
    ],
    faq: [
      {
        name: "start-using",
        title: "At what age can my pup start using Skin & Coat care chews?",
        content:
          "Our skin & coat soft chews are an amazing solution for dogs of any breed. We recommend introducing this supplement slowly, when they are around 12 weeks old. If you have concerns or questions please reach out to your vet as well as us, we are here 24/7.",
      },
      {
        name: "how-many-in-tub",
        title: "How many Skin & Coat chews come in a tub?",
        content: "Each tub contains 30 chews.",
      },
      {
        name: "what-flower",
        title: "What flavor are Medipups’ Skin & Coat chews?",
        content: "Smoked Salmon Flavored: Our chews will leave your pup begging for MORE!",
      },
      {
        name: "chews-is-safe",
        title: "What happens if I accidentally give my dog too many Skin & Coat chews?",
        content:
          "Please see a veterinarian as soon as possible if you accidentally give too many. If you’re unsure how many chews to give your dog, please see the directions for use at the top of the page.",
      },
      {
        name: "product-safe-for-humans",
        title: "Is this product safe for humans?",
        content:
          "All our products are intended to be consumed by dogs and are not safe for human consumption. Please keep packages out of reach of children",
      },
    ],
    image: "/images/belt/black.png",
    images: {
      mainImage: "/images/belt/black.png",
      mainProductImage: "/images/belt/black.png",
      benefitsImage: "/images/belt/benefit.png",
      benefitsImageMobile: "/images/belt/benefit.png",
      additionalImages: getAdditionalImagesPaths("belt/additional", 3, "png"),
      ingredients: getAdditionalImagesPaths("skin-coat/ingredients", 3, "jpg"),
      quantityImages: ["/images/belt/black.png"],
      feedingGuide: "/images/skin-coat/gallery/4.jpg",
    },
    realResults: null,
    imageIngredients: [],
    fullIngredients: [],
  },
};

export const getProductDataByName = (productName: ProductName): ProductData => {
  return productData[productName];
};
