/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import classnames from "classnames";
import { Link, PageProps } from "gatsby";

import Button from "@components/common/Button";
import Icon from "@components/common/Icon";

import { PRODUCTS_ORDER, Product } from "@helpers/getProduct";
import { getProducts } from "@helpers/getProducts";

import useLocalStorageState from "use-local-storage-state";
import { gaSelectItem, gaViewItemList } from "@analytics/google";

const ProductDropDownMenu: FC<{
  toggleMenu: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>) => void;
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  location: PageProps["location"];
}> = ({ toggleMenu, isActive, setIsActive, location }) => {
  const [bannerIsOpen] = useLocalStorageState<boolean>("isOpenPromoCodeBanner");
  const prevPathRef = useRef(location?.pathname);

  const orderedProducts = useMemo(() => {
    const typedProducts = getProducts() as Product[];

    return typedProducts.sort(
      (a, b) => PRODUCTS_ORDER.indexOf(a.slug) - PRODUCTS_ORDER.indexOf(b.slug)
    );
  }, []);

  useEffect(() => {
    if (location?.pathname !== prevPathRef.current) {
      setIsActive(false);
      prevPathRef.current = location?.pathname;
    }
  }, [location?.pathname, setIsActive]);

  useEffect(() => {
    gaViewItemList({ products: orderedProducts });
  }, []);

  const toggleActive = useCallback((): void => {
    setIsActive(!isActive);
  }, [isActive, setIsActive]);

  const onMouseEnter = (): void => {
    setIsActive(true);
  };

  return (
    <div
      className={classnames("products-items", { "is-active": isActive })}
      onMouseEnter={onMouseEnter}
    >
      <Button
        icon="chevron"
        color="transparent"
        className={classnames("products-drop-down", { "is-active": isActive })}
        iconClassName={classnames("button-icon", { "is-active": isActive })}
        onClick={toggleActive}
      >
        Supplements
      </Button>

      <div
        className={classnames("products-body", { "is-active": isActive })}
        style={{
          paddingTop: "12px",
        }}
      >
        <div className="body-wrapper">
          <Button
            icon="chevron"
            color="transparent"
            className={classnames("products-drop-down", { "is-active": isActive })}
            onClick={toggleActive}
            iconClassName={classnames("button-icon", { "is-active": isActive })}
          >
            Supplements
          </Button>
          <div className="product-cards" onClick={toggleMenu}>
            {orderedProducts.map((product) => (
              <Link
                key={product?.id}
                to={`/product/${product?.slug}`}
                onClick={() => {
                  gaSelectItem(product);
                }}
                className="product-card-header"
              >
                <div className="image-container">
                  <img
                    alt={`${product.name} - Medipups`}
                    src={product?.images.mainImage}
                    width={180}
                    height={180}
                    loading="eager"
                  />
                </div>
                <div className="product-description">
                  <div className="title text">{product?.secondName}</div>
                  <span className="description">{product?.description}</span>
                </div>
              </Link>
            ))}
          </div>

          <Link to="/shop" className="all-products-link" onClick={toggleMenu}>
            See all supplements <Icon name="chevron" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductDropDownMenu;
